import { create } from 'zustand'

interface PlayerState {
  isPlaying: boolean
  wasPlayingWhenCountdownStarted: boolean
  setIsPlaying: (isPlaying: boolean) => void
  setWasPlayingWhenCountdownStarted: (wasPlayingWhenCountdownStarted: boolean) => void
}

export const usePlayerStore = create<PlayerState>((set) => ({
  isPlaying: false,
  wasPlayingWhenCountdownStarted: false,
  setIsPlaying: (isPlaying) => set({ isPlaying }),
  setWasPlayingWhenCountdownStarted: (wasPlayingWhenCountdownStarted) => set({ wasPlayingWhenCountdownStarted })
}))
