import * as Tone from 'tone'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { Track } from '../context/audio-provider'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { v4 } from 'uuid'
// import { captureException } from '@sentry/nextjs'
import superjson from 'superjson'
import { TRPCError } from '@trpc/server'
import { TRPC_ERROR_CODE_KEY } from '@trpc/server/rpc'
import { Row } from '@tanstack/react-table'
import { Sample } from '@prisma/client'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const dateSortingFn = <TData extends Record<string, any>>(
  rowA: Row<TData>,
  rowB: Row<TData>,
  columnId: string
) => {
  const dateA = new Date(rowA.original[columnId] as string)
  const dateB = new Date(rowB.original[columnId] as string)
  return dateA.getTime() - dateB.getTime()
}

const semitoneMap: { [key: string]: number } = {
  C: 0,
  'C#': 1,
  D: 2,
  'D#': 3,
  E: 4,
  F: 5,
  'F#': 6,
  G: 7,
  'G#': 8,
  A: 9,
  'A#': 10,
  B: 11
}

export function transposeTrack(track: Track, currentKey: string, newKey: string) {
  const [currentRoot, _currentQuality] = parseKey(currentKey)
  const [targetRoot, _targetQuality] = parseKey(newKey)
  if (!(currentRoot in semitoneMap) || !(targetRoot in semitoneMap)) {
    throw new Error(`Invalid key provided. Please use one of ${Object.keys(semitoneMap)}`)
  }

  const interval = semitoneMap[targetRoot] - semitoneMap[currentRoot]

  // Cleanup old pitch shifter if exists
  if (track.pitchShiftNode) {
    track.pitchShiftNode.dispose()
  }

  // Create a new pitch shifter
  // @ts-ignore
  Tone.setContext(track.player._sounds[0]._node.context)
  const pitchShift = new Tone.PitchShift(interval)
  pitchShift.toDestination()
  try {
    // @ts-ignore
    track.player._sounds[0]._node.disconnect()
    // @ts-ignore
    Tone.connect(track.player._sounds[0]._node, pitchShift, 0, 0)
  } catch (error) {
    console.error('Error connecting pitch shift node: ', error)
  }
  track.pitchShiftNode = pitchShift
}

function parseKey(key: string): [string, string] {
  const match = key.match(/^([A-G][#b]?)(maj|min)$/)
  if (!match) {
    throw new Error("Invalid key format. Use formats like 'Cmaj', 'Fmin', etc.")
  }
  return [match[1], match[2]]
}

export const getAudioDuration = async (file: File) => {
  // @ts-expect-error - webkitAudioContext is not in the TS types
  const audioContext = new (window.AudioContext || window.webkitAudioContext)()
  const arrayBuffer = await file.arrayBuffer()
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
  return audioBuffer.duration
}

// ————— constants —————
export const musicalKeyOptions = [
  // major
  'Cmaj',
  'C#maj',
  'Dmaj',
  'D#maj',
  'Emaj',
  'Fmaj',
  'F#maj',
  'Gmaj',
  'G#maj',
  'Amaj',
  'A#maj',
  'Bmaj',
  // minor
  'Cmin',
  'C#min',
  'Dmin',
  'D#min',
  'Emin',
  'Fmin',
  'F#min',
  'Gmin',
  'G#min',
  'Amin', // a minorrrrrrr
  'A#min',
  'Bmin'
]

const getExtensionFromType = (type: string): string => {
  switch (type) {
    case 'audio/mpeg':
      return '.mp3'
    case 'audio/wav':
      return '.wav'
    case 'audio/ogg':
      return '.ogg'
    case 'audio/webm':
      return '.webm'
    default:
      return ''
  }
}

export const downloadAsZip = async (
  downloads: {
    data: Blob | File | null
    name?: string
  }[],
  zipFilename: string
) => {
  if (!downloads) return
  const zip = new JSZip()

  const filenamesThusFar: string[] = []

  await Promise.all(
    downloads.map(async (download) => {
      if (!download.data) return

      try {
        // JSZip bug: JSZip will replace/overwrite files with the same name, and there is no option to override this.
        let name = download.name || v4()
        let nameCounter = 0
        filenamesThusFar.forEach((filename) => {
          if (filename === name) nameCounter++
        })
        filenamesThusFar.push(name)
        if (nameCounter) name = `${name}_${nameCounter}`
        const extension = getExtensionFromType(download.data.type)
        name = `${name}${extension || '.mp3'}`

        zip.file(name, download.data)
      } catch (error) {
        console.error(error)
        throw error
      }
    })
  )

  // generate the zip file and trigger the download
  const content = await zip.generateAsync({ type: 'blob' })
  saveAs(content, `${zipFilename}.zip`)
}

export const formatTrpcError = (err: any): { message: string; code?: string; errorsByKey?: Record<string, string> } => {
  if (err?.name !== 'TRPCClientError') {
    return {
      message: err?.message ?? err?.data?.message,
      code: undefined
    }
  }

  const error = err as any
  return {
    message: error?.data?.message as string,
    code: error?.data?.code as string,
    errorsByKey: error?.data.errorsByKey
  }
}

export class TRPCMutationError extends TRPCError {
  type = 'mutationError'
  errorsByKey?: Record<string, string>

  constructor(opts: {
    message?: string
    code: TRPC_ERROR_CODE_KEY
    cause?: unknown
    errorsByKey?: Record<string, string>
  }) {
    const { errorsByKey, ...trpcOpts } = opts
    super(trpcOpts)

    this.errorsByKey = errorsByKey
  }
}

export const safelyParseJson = (json: string) => {
  if (json === '' || json === 'undefined' || typeof json === 'undefined') return
  try {
    return superjson.parse(json)
  } catch (e) {
    console.error('Parsing JSON failed:', e)
    // captureException(e, { extra: { json } })
  }
}

export async function getAudioBase64(url: string) {
  try {
    const response = await fetch(url)
    const blob = await response.blob()
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  } catch (error) {
    console.error('Error fetching and converting audio:', error)
    throw error // Re-throw the error if needed elsewhere
  }
}

export type Preview = {
  src: string
  player: Howl
  startTime: number
  duration: number
}

export function playSound(preview: Preview, title: string) {
  // @ts-ignore
  const sounds = preview.player._sounds
  if (sounds.length > 0) {
    let id
    if (sounds.length === 1) {
      // This is necessary, in the case of 1 item in sounds, findLast is not defined
      id = sounds[0]._id
    } else {
      id = sounds.findLast((s: any) => s._sprite === title)._id
    }
    try {
      preview.player.play(id)
      // if (getBpmState().currentBpm) preview.player.rate(getBpmState().currentBpm / sample.bpm, id)
    } catch (error) {
      // This catches a special case where the track has been created but not yet has an id
      // console.error('Error playing track: ', error)
      try {
        preview.player.play(title)
      } catch (error) {
        console.error('Error playing track: ', error)
        debugger
      }
      // if (getBpmState().currentBpm) preview.player.rate(getBpmState().currentBpm / track.data[0].bpm)
    }
  }
}

export function parsePrettyTitle(sampleData: Sample) {
  let uniqTitleArray = sampleData.title.split('####')

  if (uniqTitleArray.length < 2) {
    uniqTitleArray = sampleData.title.split('###')
  }

  return uniqTitleArray[0]
}
