'use client'

import { usePathname } from 'next/navigation'
import { useSkin } from '../context/skin-provider'
import { cn } from '../lib/utils'

const SkinBackground = () => {
  const { skin } = useSkin()
  const pathname = usePathname()
  if (pathname === '/admin/create') return null
  if (pathname.startsWith('/admin/edit/')) return null
  return (
    <video
      className={cn('absolute inset-0 -z-50 h-screen w-screen object-cover opacity-50 transition-all duration-300')}
      key={skin.video}
      autoPlay
      muted
      loop
      playsInline
      controls={false}
    >
      <source src={skin.video} type="video/mp4" />
    </video>
  )
}

export default SkinBackground
