'use client'

import React, { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import * as Tone from 'tone'
import { Howl, SoundSpriteDefinitions } from 'howler'

import { useBpmStore } from '../lib/bpmStore'
import { usePlayerStore } from '../lib/playerStore'
import { transposeTrack } from '../lib/utils'
import { Sample, SongData } from '../types'

// ————— types —————

export type Track = {
  id: string
  src: string
  player: Howl
  data: SongData[] | Sample[]
  title: string
  isSoloed?: boolean
  isMuted?: boolean
  pitchShiftNode?: Tone.PitchShift
}

type TracksContextState = {
  tracks: Track[]
  setTracks: Dispatch<React.SetStateAction<Track[]>>
  playAll: () => void
  pauseAll: () => void
  seekAll: (time: number) => void
  stopAll: () => void
  toggleMuteTrack: (track: Track) => void
  toggleSoloTrack: (track: Track) => void
  getPlayer: (
    src: string,
    title: string,
    sourceBpm: number,
    targetBpm: number,
    duration?: number,
    isPreview?: boolean
  ) => Howl
  addNewTrack: (
    // TODO: clean up data shape between this a Sample
    songData: SongData[],
    title: string,
    sourceBpm: number,
    targetBpm: number,
    duration?: number,
    isPreview?: boolean
  ) => Promise<Track | void>
  deleteTrack: (track: Track) => void
  updateBpm: (nextBpm: number, isReset?: boolean) => Promise<void>
  updateKey: (prevKey: string, nextKey: string) => Promise<void>
  lockedDuration?: number
  setLockedDuration: Dispatch<SetStateAction<number | undefined>>
  allTracksLoaded: boolean
  setAllTracksLoaded: Dispatch<SetStateAction<boolean>>
}

// ————— context —————
const defaultState: TracksContextState = {
  tracks: [],
  setTracks: () => {},
  playAll: () => {},
  pauseAll: () => {},
  seekAll: () => {},
  stopAll: () => {},
  toggleMuteTrack: () => {},
  toggleSoloTrack: () => {},
  getPlayer: () => new Howl({ src: [] }),
  addNewTrack: async (
    _songData: SongData[] | Sample[],
    _title: string,
    _currentBpm?: number,
    _targetBpm?: number,
    _duration?: number
  ) => {},
  deleteTrack: () => {},
  updateBpm: async () => {},
  updateKey: async () => {},
  lockedDuration: undefined,
  setLockedDuration: () => {},
  allTracksLoaded: false,
  setAllTracksLoaded: () => {}
}
const TracksContext = createContext<TracksContextState>(defaultState)

// ————— provider —————

export const TracksProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // ————— local state —————

  const getBpmState = useBpmStore.getState
  const setIsPlaying = usePlayerStore((state) => state.setIsPlaying)
  const [tracks, setTracks] = useState<Track[]>([])
  const [lockedDuration, setLockedDuration] = useState<number>()
  const [allTracksLoaded, setAllTracksLoaded] = useState(false)

  // ————— effects —————

  useEffect(() => {
    console.log('USE EFFECT TRACKS: ', tracks.length)
    const anySoloed = tracks.some((t) => t.isSoloed)
    tracks.forEach((track) => {
      if (anySoloed) {
        // If any track is soloed, check for both solo and mute states
        if (!!track.isSoloed) {
          if (!!track.isMuted) {
            track.player.mute(true)
          } else {
            track.player.mute(false)
          }
        } else {
          track.player.mute(true)
        }
      } else {
        // No tracks are soloed, respect each track's individual mute state
        if (!!track.isMuted) {
          track.player.mute(true)
        } else {
          track.player.mute(false)
        }
      }
    })
  }, [tracks])

  const setRateIfNeeded = (track: Track, id?: number) => {
    if (!getBpmState().currentBpm) return
    // @ts-ignore
    const nextRate = getBpmState().currentBpm / track.data[0].bpm
    console.log('NEXT RATE', nextRate, 'track rate(): ', track.player.rate())
    console.log('HELLO RETURNING?', track.player.rate() === nextRate)
    if (track.player.rate() === nextRate) return
    if (id) track.player.rate(nextRate, id)
    else track.player.rate(nextRate)
  }

  const updateBpm = async (currentBpm: number) => {
    tracks.forEach((track) => {
      // @ts-ignore
      const sounds = track.player._sounds
      // @ts-ignore
      if (track.player._sprite[track.title] && sounds.length > 1) {
        const id = sounds.findLast((s: any) => s._sprite === track.title)._id
        setRateIfNeeded(track, id)
      }
      try {
        setRateIfNeeded(track)
      } catch (error) {
        console.log('ERROR SETTING RATE: ', error, currentBpm, track.data[0].bpm, 'THE TRACK: ', track)
      }
    })
  }

  const updateKey = async (currentKey: string, nextKey: string) => {
    tracks.forEach((track) => transposeTrack(track, currentKey, nextKey))
  }

  // ————— controls for all tracks —————

  const playAll = () => {
    tracks.forEach((track) => {
      // @ts-ignore
      if (track.player._sprite[track.title]) {
        // @ts-ignore
        const sounds = track.player._sounds
        if (sounds.length > 0) {
          let id
          if (sounds.length === 1) {
            // This is necessary, in the case of 1 item in sounds
            id = sounds[0]._id
          } else {
            id = sounds.findLast((s: any) => s._sprite === track.title)._id
          }
          try {
            track.player.play(id)
            // @ts-ignore
            if (getBpmState().currentBpm) setRateIfNeeded(track, id)
          } catch (error) {
            // This catches a special case where the track has been created but not yet has an id
            // console.error('Error playing track: ', error)
            track.player.play(track.title)
            setRateIfNeeded(track)
          }
        }
      } else {
        track.player.play()
        setRateIfNeeded(track)
      }
    })
    setIsPlaying(true)
  }

  const pauseAll = () => {
    tracks.forEach((track) => track.player.pause())
    setIsPlaying(false)
  }

  const seekAll = (time: number) => {
    // time in seconds
    tracks.forEach((track) => track.player.seek(time))
  }

  const stopAll = () => {
    tracks.forEach((track) => track.player.stop())
    setIsPlaying(false)
  }

  // ————— controls for individual tracks —————

  const deleteTrack = (track: Track) => {
    track.player.stop()
    track.player.unload()
    // @ts-ignore
    track.player = null
    // @ts-ignore
    delete track.player
    if (track.pitchShiftNode) track.pitchShiftNode.dispose()
    setTracks((prevTracks) => prevTracks.filter((t) => t.id !== track.id))
  }

  const toggleMuteTrack = (track: Track) => {
    // update track object only, handle actual playback adjustments above in useEffect
    setTracks((prev) => prev.map((t) => (t.id === track.id ? { ...t, isMuted: !t.isMuted } : t)))
  }

  const toggleSoloTrack = (track: Track) => {
    // update track object only, handle actual playback adjustments above in useEffect
    setTracks((prev) => prev.map((t) => (t.id === track.id ? { ...t, isSoloed: !t.isSoloed } : t)))
  }

  const getPlayer = (
    src: string,
    title: string,
    sourceBpm: number,
    targetBpm: number,
    duration?: number,
    isPreview?: boolean
  ) => {
    // TODO: if there is a currentBPM set, the calculation for newly added samples is incorrect.
    const playbackRate = targetBpm / sourceBpm
    // @ts-ignore
    let player

    const getSprite = (title: string, duration: number, isPreview?: boolean): SoundSpriteDefinitions => ({
      [title]: [0, duration, !isPreview]
    })

    if (duration) {
      // newDuration always needs to be based on the set bpm (of the first added track) otherwise,
      // if it is calculated based off of currentBpm, new durations will be out of sync
      const newDuration = duration * (getBpmState().bpm / sourceBpm)
      const newSprite = getSprite(title, newDuration, isPreview) as SoundSpriteDefinitions
      player = new Howl({
        src: [src],
        sprite: newSprite,
        volume: 0.5,
        rate: playbackRate,
        preload: true,
        onload: () => {
          if (!isPreview) setAllTracksLoaded(tracks.every((t) => t.player.state() === 'loaded'))
        },
        onloaderror: (id, err) => {
          // @ts-ignore
          player.unload()
          // @ts-ignore
          player.load()
        }
      })
    } else {
      player = new Howl({
        src: [src],
        volume: 0.75,
        rate: playbackRate,
        loop: true,
        onload: function () {
          if (!isPreview) setAllTracksLoaded(tracks.every((t) => t.player.state() === 'loaded'))
        }
      })
    }

    return player
  }

  const addNewTrack = async (
    songData: SongData[] | Sample[],
    title: string,
    sourceBpm: number,
    targetBpm: number,
    duration?: number,
    isPreview?: boolean
  ): Promise<Track> => {
    stopAll()
    setIsPlaying(false)

    const audioSrc = typeof songData[0].audio === 'string' ? songData[0].audio : ''
    const newTrack: Track = {
      id: v4(),
      src: audioSrc,
      data: songData,
      player: getPlayer(audioSrc, title, sourceBpm, targetBpm, duration, isPreview),
      title
    }
    setTracks((prev) => [...prev, newTrack])
    return newTrack
  }

  // ————— render —————

  return (
    <TracksContext.Provider
      value={{
        tracks,
        setTracks,
        playAll,
        pauseAll,
        seekAll,
        stopAll,
        deleteTrack,
        toggleMuteTrack,
        toggleSoloTrack,
        getPlayer,
        addNewTrack,
        updateBpm,
        updateKey,
        lockedDuration,
        setLockedDuration,
        allTracksLoaded,
        setAllTracksLoaded
      }}
    >
      {children}
    </TracksContext.Provider>
  )
}

// ————— hooks —————
export const useTracks = () => useContext(TracksContext)
